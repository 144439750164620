function initFullscreenVideo (videoId) {
  let player;
  let isYoutubeVideo = false;
  let html5Player;

  const videoLayerContainer = document.querySelector(`.video-layer-container[data-video-id="${videoId}"]`);
  if (!videoLayerContainer) {
    return;
  }

  [...document.querySelectorAll('.video-layer-container')].forEach(item => item.classList.remove('open'));

  if (videoLayerContainer.querySelector('.zentis-external-video')) {
    if (videoLayerContainer.querySelector('.video_trigger').dataset.type === 'youtube') {
      isYoutubeVideo = true;
    }
  } else {
    html5Player = videoLayerContainer.querySelector('.zentis-internal-video');
  }

  const videoLayerClose = videoLayerContainer.querySelector('.video-layer-close');

  const cookiePolicyButton = document.querySelector('.cc-revoke');

  const openVideoLayer = () => {
    document.documentElement.classList.add('no-scroll');
    if (cookiePolicyButton) {
      cookiePolicyButton.style.display = 'none';
    }
    videoLayerContainer.classList.add('open');
  };

  const closeVideoLayer = () => {
    if (isYoutubeVideo) {
      if (player) {
        player.pauseVideo();
      }
    } else {
      html5Player.pause();
    }
    document.documentElement.classList.remove('no-scroll');
    videoLayerContainer.classList.remove('open');
    window.history.replaceState(null, null, ' ');

    if (cookiePolicyButton) {
      cookiePolicyButton.style.removeProperty('display');
    }
  };


  videoLayerClose.addEventListener('click', () => {
    closeVideoLayer();
  });

  openVideoLayer();

  if (isYoutubeVideo) {
    window.onYouTubeIframeAPIReady = () => {
      const ytPlayerId = videoLayerContainer.querySelector('iframe').getAttribute('id');
      console.log('init player');
      player = new YT.Player(ytPlayerId, {
        rel: 0,
        modestbranding: 0,
      });
    };
  }
}


const processUrlHash = () => {
  const videoFullscreenHash = decodeURI(location.hash.substring(1)).split('|');
  if (videoFullscreenHash.length === 2 || videoFullscreenHash[1] === 'vfs') {
    const videoId =  videoFullscreenHash[0];
    initFullscreenVideo(videoId);
  }
};

window.addEventListener('hashchange', processUrlHash);
processUrlHash();





