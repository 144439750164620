const linksWithHash = document.querySelectorAll('a[href*="#"]:not(.scroll-top)');

const anchorLinks = [...linksWithHash].filter(item => {
  if (item.attributes.href.value === '#') {
    return false;
  }


  if (item.attributes.href.value.match(/^http/)) {
    return false;
  }

  const linkPathname = (new URL(item.href)).pathname;
  if (linkPathname !== location.pathname) {
    return false;
  }

  return true;
});

let intervalId = 0;
let scrollToOffset = 0;
let scrollToDirection = 'down';
// let speedInMs = 800;
let scrollByAmountOfPixels = 50;

function scrollStep() {
  let scrollPosition = scrollToDirection === 'up' ? window.pageYOffset - scrollByAmountOfPixels : window.pageYOffset + scrollByAmountOfPixels;
  if (scrollToDirection === 'up') {
    scrollPosition = scrollPosition < scrollToOffset ? scrollToOffset : scrollPosition;
  }else {
    scrollPosition = scrollPosition < scrollToOffset ? scrollPosition : scrollToOffset;
  }
  if (
    (scrollToDirection === 'up' && window.pageYOffset <= scrollToOffset) ||
    (scrollToDirection === 'down' && window.pageYOffset >= scrollToOffset)
  ) {
    clearInterval(intervalId);
  }
  window.scroll(0, scrollPosition);
}

function scrollToAnchor(anchorElement) {
  scrollToOffset = Math.floor(anchorElement.getBoundingClientRect().top + window.pageYOffset);
  scrollToDirection = window.pageYOffset < scrollToOffset ? 'down' : 'up';

  /* Calculate Speed for Scrolling, maybe needed later */
  // const scrollPixelDiff = window.pageYOffset > scrollToOffset ? window.pageYOffset - scrollToOffset : scrollToOffset - window.pageYOffset;
  // scrollByAmountOfPixels =  Math.floor(scrollPixelDiff / speedInMs * 16.66);
  intervalId = setInterval(scrollStep, 16.66);
}

function handleScrollToAnchor(event) {
  const element = event.currentTarget;
  const hrefValue = element.attributes.href.value;
  const hashName = hrefValue.split('#')[1];

  const targetScrollToElement = document.getElementById(hashName);

  if (targetScrollToElement === null) {
    return;
  }

  event.preventDefault();
  scrollToAnchor(targetScrollToElement);
}


anchorLinks.forEach(item => {
  item.addEventListener('click', handleScrollToAnchor);
});
