const dropDownMenus = document.querySelectorAll('.dropdown-menu');

const closeDropDownMenu = ddMenu => {
  ddMenu.classList.remove('opened');
};

dropDownMenus.forEach(ddMenu => {
  const trigger = ddMenu.querySelector('.dropdown-title');
  if(!trigger){
      return;
    };
  const handleClickBodyToCloseDropdown = (e) => {
    closeDropDownMenu(ddMenu);
    document.body.removeEventListener('click', handleClickBodyToCloseDropdown);
  };
  trigger.addEventListener('click', (e) => {
    e.stopPropagation();
    if (ddMenu.classList.contains('opened')) {
      ddMenu.classList.remove('opened');
      document.body.removeEventListener('click', handleClickBodyToCloseDropdown);
    }else {
      ddMenu.classList.add('opened');
      document.body.addEventListener('click', handleClickBodyToCloseDropdown);
    }
  });
});