let intervalId = 0;
const scrollButton = document.querySelector('.scroll-top');

if (scrollButton) {
    const stageElement = document.querySelector('.header-stage');
    let scrollToOffset = 0;

    function scrollStep() {
        let scrollPosition = window.pageYOffset - 50;
        scrollPosition = scrollPosition < scrollToOffset ? scrollToOffset : scrollPosition;
        if (window.pageYOffset <= scrollToOffset) {
            clearInterval(intervalId);
        }
        window.scroll(0, scrollPosition);
    }

    function scrollToTop(event) {
        event.preventDefault();
        if (stageElement) {
            scrollToOffset = stageElement.offsetHeight;
        }
        intervalId = setInterval(scrollStep, 16.66);
    }

    function toggleScrollButton() {
        if (window.pageYOffset > 400) {
            scrollButton.classList.add('show');
        } else {
            scrollButton.classList.remove('show');
        }
    }

    window.addEventListener('scroll', toggleScrollButton);
    scrollButton.addEventListener('click', scrollToTop);
}


