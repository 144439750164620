const modal = document.createElement('div');
modal.setAttribute('id', 'my-modal');
modal.classList.add('modal');
modal.innerHTML = '' +
    '    <div class="modal-content">\n' +
    '      <span id="modal-close" class="icon icon-close"></span>\n' +
    '      <div id="modal-inner"></div>\n' +
    '    </div>\n';
document.body.appendChild(modal);

if (modal) {
    document.getElementById("modal-close").onclick = function () {
        modal.style.display = "none";
    };

    window.addEventListener('click', function (event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    });
}

const productNutritions = document.querySelectorAll('.product-nutritions');
productNutritions.forEach(function (productNutrition) {
    const productNutritionEls = productNutrition.querySelectorAll('.pn-els .pn-el');
    if (productNutritionEls) {
        productNutritionEls.forEach(function (item) {
            item.addEventListener('click', function (evt) {
                evt.preventDefault();
                if (window.innerWidth < 768) {
                    const newRow = productNutrition.querySelector('.pn-detail[data-id="' + item.getAttribute('data-target') + '"]');
                    if (newRow) {
                        document.getElementById("modal-inner").innerHTML = '';
                        document.getElementById("modal-inner").appendChild(newRow.cloneNode(true));
                        const closestContainer = productNutrition.closest(".container");
                        const themeClass = closestContainer ? [...closestContainer.classList].find((e) => {return e.match("theme");}) : "";
                        modal.className = "modal " + themeClass;
                        modal.style.display = "block";
                    }
                } else {
                    const myTarget = productNutrition.querySelector('.pn-detail[data-id="' + item.getAttribute('data-target') + '"]');
                    if (myTarget) {
                        const productNutritionDetails = productNutrition.querySelectorAll(".pn-detail");
                        productNutritionDetails.forEach(function (el) {
                            el.style.display = "none";
                        });
                        myTarget.style.display = 'block';
                    }
                }
            });
        });
    }
});


