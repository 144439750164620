const mainNav = document.querySelector('.main-nav');

if (mainNav) {
  const navSecondWrapper = document.querySelector('.main-nav .nav-second-wrapper');
  const navSecondContainer = document.querySelectorAll('.main-nav .nav-second-container');
  const navTrigger = document.querySelector('.main-nav .nav-trigger');
  let currentOpenedSecondContainer = null;
  const navItemsWithSecondContainer = document.querySelectorAll('.main-nav .nav-item[data-nav-open]');

  const closeAllSecondContainer = (noTimeout = false) => {
    navSecondWrapper.classList.remove('active');
    const closeSecondContainers = [...navSecondContainer].filter(item => item.classList.contains('active'));
    setTimeout(() => {
      closeSecondContainers.forEach(item => item.classList.remove('active'));
    }, noTimeout ? 0 : 500);

    currentOpenedSecondContainer = null;
  };

  const secondWrapperCloseButtons = document.querySelectorAll('.nav-close-second-wrapper');
  if (secondWrapperCloseButtons.length) {
    secondWrapperCloseButtons.forEach(item => {
      item.addEventListener('click', () => {
        closeAllSecondContainer();
      });
    });
  }

  /* man-nav proximity handler/effects */
  (() => {
    const proximityHelper = document.querySelector('.main-nav-proximity-helper');
    proximityHelper.addEventListener('mouseenter', () => {
      mainNav.classList.add('open-highlight');
    });
    proximityHelper.addEventListener('mouseleave', () => {
      mainNav.classList.remove('open-highlight');
    });
  })();

  /* main-nav functions */
  (() => {
    let mainNavLeaveTimeout = null;
    let isDesktopNavActive = false;

    const openMainNav = () => {
      mainNav.classList.remove('open-highlight');
      mainNav.classList.add('opened');
      document.documentElement.classList.add('no-scroll');
      clearTimeout(mainNavLeaveTimeout);
    };

    const closeMainNav = (closeTimeout=500) => {
      mainNavLeaveTimeout = setTimeout(() => {
        closeAllSecondContainer();
        mainNav.classList.remove('opened');
        document.documentElement.classList.remove('no-scroll');
      }, closeTimeout);
    };

    const openMainNavEvent = (e) => {
      openMainNav();
    };

    const closeMainNavEvent = (e) => {
      closeMainNav();
    };

    const handleClickNavItemMobile = (e) => {
      const relatedNavItem = e.target.closest('.nav-item[data-nav-open]');
      if (relatedNavItem && navSecondWrapper.querySelector(`[data-nav-name="${relatedNavItem.dataset.navOpen}"]`)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const activateDesktopNav = () => {
      isDesktopNavActive = true;
      mainNav.addEventListener('mouseenter', openMainNavEvent);
      mainNav.addEventListener('mouseleave', closeMainNavEvent);

      mainNav.removeEventListener('click', handleClickNavItemMobile);
    };

    const deactivateDesktopNav = () => {
      isDesktopNavActive = false;
      mainNav.removeEventListener('mouseenter', openMainNavEvent);
      mainNav.removeEventListener('mouseleave', closeMainNavEvent);

      //disable default click on links in first layer, so that we can open the second layer instead
      mainNav.addEventListener('click', handleClickNavItemMobile);
    };

    navTrigger.addEventListener('click', () => {
      if (mainNav.classList.contains('opened')) {
        closeMainNav(0);
      } else {
        openMainNav();
      }
    });

    const handleWindowResize = () => {
      if(window.outerWidth > 768){
        if (!isDesktopNavActive) {
          activateDesktopNav();
        }
      } else {
        if (isDesktopNavActive) {
          deactivateDesktopNav();
        }
      }
    };

    window.addEventListener('resize', handleWindowResize);
    deactivateDesktopNav();
    handleWindowResize();
  })();

  /* nav-item functions */
  (() => {

    let navSecondWrapperCloseTimeout = null;

    const closeNavSecondWrapperWithTimeout = () => {
      clearTimeout(navSecondWrapperCloseTimeout);
      navSecondWrapperCloseTimeout = setTimeout(() => {
        closeAllSecondContainer();
      }, 500);
    };

    navSecondWrapper.addEventListener('mouseleave', closeNavSecondWrapperWithTimeout);
    navSecondWrapper.addEventListener('mouseenter', () => {
      clearTimeout(navSecondWrapperCloseTimeout);
    });
    // navMenuList.addEventListener('mouseleave', closeNavSecondWrapperWithTimeout);

    let openSecondNavByNameTimeout = null;
    let secondContainerAnimateOpenTimeout = null;
    const openSecondNavByName = name => {
      clearTimeout(navSecondWrapperCloseTimeout);
      if (currentOpenedSecondContainer && currentOpenedSecondContainer.dataset.navName === name) {
        return;
      }
      closeAllSecondContainer(true);
      //clear previous animation open timeouts, this is needed when we move the mouse quickly between the menu entries
      clearTimeout(secondContainerAnimateOpenTimeout);

      const relatedNavSecondContainer = [...navSecondContainer].find(item => item.dataset.navName === name);
      if (relatedNavSecondContainer) {
        navSecondWrapper.classList.remove('active');
        //this timeout is needed to do an slide animation between subnavigation changes
        secondContainerAnimateOpenTimeout = setTimeout(() => {
          navSecondWrapper.classList.add('active');
          if (relatedNavSecondContainer) {
            relatedNavSecondContainer.classList.add('active');
          }
        }, 200);
        currentOpenedSecondContainer = relatedNavSecondContainer;

      }
    };

    const onMouseEnterNavItem = (e) => {
      clearTimeout(navSecondWrapperCloseTimeout);
      const { navOpen } = e.target.dataset;
      //if currentOpenedSecondContainer equals to the one we want to open, then just call the open function
      //this is necessary to quit other timeouts which closes the opened secondNavWrapper
      if (currentOpenedSecondContainer && currentOpenedSecondContainer.dataset.navName === navOpen) {
        openSecondNavByName(navOpen);
      }

      //if the navSecondWrapper allreay opened, then call the openSecondNavByName with a timeout.
      //the timeout is cleared on leaving the navItem.
      //this prevents the quick switching of secondNavContainer when the mouse cursor is moved across naItems quickly
      if (navSecondWrapper.classList.contains('active')) {
        openSecondNavByNameTimeout = setTimeout(() => {
          openSecondNavByName(navOpen);
        }, 500);
      } else {
        openSecondNavByName(navOpen);
      }
    };

    const onMouseLeaveNavItem = (e) => {
      clearTimeout(openSecondNavByNameTimeout);
      closeNavSecondWrapperWithTimeout();
    };

    navItemsWithSecondContainer.forEach(item => {
      item.addEventListener('mouseenter', onMouseEnterNavItem);
      item.addEventListener('focus', onMouseEnterNavItem);
      item.addEventListener('mouseleave', onMouseLeaveNavItem);
    });
  })();
}


/*document.body.addEventListener("touchmove", function(e) {
  document.documentElement.classList.contains("no-scroll") && e.preventDefault();
}, {passive: false});*/
