const fadeInElements = document.querySelectorAll('.fade-in');

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible');
      observer.unobserve(entry.target);
    }
  });
});

[ ...fadeInElements ].forEach(fadeInElemnt => {
  observer.observe(fadeInElemnt);
});