import autoComplete from "@tarekraafat/autocomplete.js/dist/js/autoComplete";
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.css";


const autoSuggestInputsSelectors = ['.nav-search-form input[name=q]', '.search-form input[name=q]'];

autoSuggestInputsSelectors.forEach(autoSuggestInputSelector => {
  const autoSuggestInput = document.querySelector(autoSuggestInputSelector);
  if (!autoSuggestInput) {
    return;
  }
  const searchForm = autoSuggestInput.closest('form');
  const autoSuggestUrl = searchForm.dataset.suggestUrl;


  new autoComplete({
    data: {
      src: () => {
        const query = autoSuggestInput.value;
        return fetch(`${autoSuggestUrl}?q=${query}`)
          .then(resp => resp.json())
          .then(resp => resp.result);
      },
    },
    observer: true,
    selector: autoSuggestInputSelector,
    debounce: 300,
    maxResults: 10,
    highlight: {
      render: true,
    },
    onSelection: (feedback) => {
      const selection = feedback.selection.value;
      autoSuggestInput.value = selection;
    }
  });

  autoSuggestInput.addEventListener('keydown', e => {
    if(e.key === 'Enter'){
      const selectedSuggestItem = searchForm.querySelector('.autoComplete_selected');
      if (!selectedSuggestItem) {
        HTMLFormElement.prototype.submit.call(searchForm);
      }
    }
  });

});
