const recipeContainers = document.querySelectorAll('.recipe-container');
const hideShowAnimationDuration = 200;

function RecipeList (recipeContainer) {


  const initCategoriesScrollFunctions = () => {
    //Categories Scroll function
    const categoryRow = recipeContainer.querySelector('.recipe-search-categories-wrapper .form-row');
    const categoryRowInner = recipeContainer.querySelector('.recipe-search-categories-wrapper .form-row .form-inline');
    const catScrollButtons = recipeContainer.querySelectorAll('.recipe-categories-scroll-buttons');
    const catScrollLeftButton = recipeContainer.querySelector('.recipe-categories-prev');
    const catScrollRightButton = recipeContainer.querySelector('.recipe-categories-next');

    const handleScrollButtonVisibility = () => {
      const maxScrollLeft =  (categoryRowInner.offsetWidth - categoryRow.offsetWidth);
      catScrollLeftButton.style.display = categoryRow.scrollLeft === 0 ? 'none' : 'flex';
      catScrollRightButton.style.display = categoryRow.scrollLeft >= maxScrollLeft ? 'none' : 'flex';
    };
    handleScrollButtonVisibility();

    //on reinitialization, remove previous events and bind the new one
    window.removeEventListener('resize', handleScrollButtonVisibility);
    window.addEventListener('resize', handleScrollButtonVisibility);
    categoryRow.addEventListener('scroll', handleScrollButtonVisibility);

    catScrollButtons.forEach(scrollButton => {
      scrollButton.addEventListener('click', (e) => {
        const { direction } = e.currentTarget.dataset;
        console.log(direction);
        console.log('scrollLeft:', categoryRow.scrollLeft);
        let newScrollLeft = 0;
        const maxScrollLeft =  (categoryRowInner.offsetWidth - categoryRow.offsetWidth);

        if (direction === 'left') {
          newScrollLeft = categoryRow.scrollLeft - (window.outerWidth * 0.7);
          newScrollLeft = newScrollLeft > 0 ? newScrollLeft : 0;
        }
        if (direction === 'right') {
          newScrollLeft = categoryRow.scrollLeft + (window.outerWidth * 0.7);
          newScrollLeft = newScrollLeft < maxScrollLeft ? newScrollLeft : maxScrollLeft;
        }

        categoryRow.scroll({
          left: newScrollLeft,
          behavior: 'smooth'
        });
      });
    });
  };

  const initForm = () => {
    const ajaxBaseUrl = recipeContainer.dataset.ajaxAction;

    const hideRecipeList = () => {
      recipeContainer.classList.add('hide-recipe-items-container');
    };
    const showRecipeList = () => {
      recipeContainer.classList.remove('hide-recipe-items-container');
    };

    let hideRecipeAnimationDone = false;

    const hideRecipeListWithTimeout = () => {
      hideRecipeList();
      setTimeout(() => {
        hideRecipeAnimationDone = true;
      }, hideShowAnimationDuration);
    };

    const showRecipeListWithTimeout = () => {
      if (hideRecipeAnimationDone) {
        showRecipeList();
        hideRecipeAnimationDone = false;
      } else {
        setTimeout(() => {
          showRecipeListWithTimeout();
        }, hideShowAnimationDuration);
      }
    };

    const runAfterAnimationTimeout = (callback => {
      if (hideRecipeAnimationDone) {
        callback();
      } else {
        setTimeout(() => { runAfterAnimationTimeout(callback);}, hideShowAnimationDuration);
      }
    });

    const initFormHandling = () => {
      const recipeListingWrapper = recipeContainer.querySelector('.recipe-listing-wrapper');
      const formElement = recipeContainer.querySelector('form[name="recipe"]');
      const recipeSearchInput = recipeContainer.querySelector('.recipe-search-input');
      const recipeItemsContainer = recipeContainer.querySelector('.recipe-items-container');
      const recipePagination = recipeContainer.querySelector('.recipe-pagination');
      const recipeResetFilterBtn = recipeContainer.querySelector('.recipe-reset-filter-btn');
      const clearRecipeCategoryButtons = recipeContainer.querySelectorAll('.recipe-selected-categories-wrapper .recipe-category-btn');

      const getAjaxUrl = () => {
        const data = new FormData(formElement);
        const queryString = new URLSearchParams(data).toString();
        return `${ajaxBaseUrl}?${queryString}`;
      };

      const submitForm = () => {
        hideRecipeListWithTimeout();

        const ajaxUrl = getAjaxUrl();
        fetch(ajaxUrl)
          .then(response => response.json())
          .then(response => {
            runAfterAnimationTimeout(() => {
              recipeListingWrapper.outerHTML = response.html;
              requestAnimationFrame(() => {
                showRecipeListWithTimeout();
                initFormHandling();
              });

            });
          });
      };

      formElement.addEventListener('submit', (event) => {
        event.preventDefault();
        submitForm();
      });

      const filterCheckboxes = formElement.querySelectorAll('input[type="checkbox"][name="recipe[cat][]"]');
      filterCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          submitForm();
        });
      });

      if (recipeResetFilterBtn) {
        recipeResetFilterBtn.addEventListener('click', (event) => {
          event.preventDefault();
          filterCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
          });
          submitForm();
        });
      }
      const bindShowMoreBtnEvent = () => {
        const showMoreBtn = recipeContainer.querySelector('.recipe-show-more-btn');

        if (showMoreBtn) {
          showMoreBtn.addEventListener('click', (event) => {
            event.preventDefault();

            let ajaxUrl = getAjaxUrl();
            const page = showMoreBtn.dataset.recipeListPage;
            ajaxUrl = `${ajaxUrl}&onlyRecipes=1&page=${page}`;
            fetch(ajaxUrl)
              .then(response => response.json())
              .then(response => {
                const newRecipeItems = document.createElement('template');
                newRecipeItems.innerHTML = response.recipesHtml;
                const newRecipeElements = newRecipeItems.content.querySelectorAll('.recipe-item');
                [...newRecipeElements].forEach(item => item.classList.add('hide-recipe-item'));
                recipeItemsContainer.appendChild(newRecipeItems.content);

                requestAnimationFrame(() => {
                  [...newRecipeElements].forEach(item => item.classList.remove('hide-recipe-item'));
                });

                recipePagination.innerHTML = response.paginationNextHtml;

                bindShowMoreBtnEvent();
              });
          });
        }
      };

      clearRecipeCategoryButtons.forEach(clearBtn => {
        clearBtn.addEventListener('click', (e) => {
          const relatedCheckboxSelector = `.recipe-search-categories-wrapper input[data-category-id="${e.currentTarget.dataset.targetCategoryId}"]`;
          const relatedCheckbox = recipeContainer.querySelector(relatedCheckboxSelector).checked = false;
          submitForm();
        });
      });

      const recipeSearchClearBtn = recipeContainer.querySelector('.recipe-search-clear-btn');
      if (recipeSearchClearBtn) {
        recipeSearchClearBtn.addEventListener('click', () => {
          recipeSearchInput.value = '';
          filterCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
          });
          submitForm();
        });
      }

      bindShowMoreBtnEvent();
      initCategoriesScrollFunctions();
    };

    initFormHandling();
  };

  initForm();
}

if (recipeContainers.length) {
  recipeContainers.forEach(recipeContainer => {
    new RecipeList(recipeContainer);
  });
}